.notification-banner {
  height: 40px;
  width: 100%;
  background-color: #feb902;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 22px;

  .content {
    display: flex;
    gap: 8px;

    .count {
      min-width: 21px;
      height: 21px;
      background-color: $eos-grey-1;
      color: white;
      border-radius: 20px;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      padding: 0 2px;
    }

    .text {
      font-weight: 400;
      font-size: 0.938rem;
      color: var(--text-grey);
    }

    .bold-text {
      font-weight: 700;
      font-size: 0.938rem;
    }
  }

  .acknowledge-btn {
    min-height: 30px;
    padding-left: 12px;
    background: none;
    color: var(--text-grey);
    border: none;
    cursor: pointer;
    border-left: 1px solid white;
    font-weight: 700;
    font-size: 15px;
  }
}

.banner-dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .banner-dialog-btns {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;

    .dialog-btn {
      font-weight: 800 ;
    }
  }

  div:first-of-type {
    p:first-of-type {
      margin-bottom: 6px;
    }
  }
}

@media (max-width: 768px) {
  .content {
    .bold-text {
        font-size: 0.75rem;

      }
      .text {
        font-size: 0.75rem;  }
  }
  .hide {
    display: none;
  }
  .notification-banner {
    height: 50px;
    padding: 8px 12px;      
  }
 
  .expand {
    min-height: 113px;
    height: auto;
    flex-direction: column;
    align-items: start;

    .notification-banner {
      padding: 8px 12px;    
    }    

    .acknowledge-btn {
      border-top: 1px solid white;
      border-left: none;
      width: 100%;
      min-height: 47px;
      margin-top: 11px;
      padding-left: 0px;
    }
    
    .acknowledge-btn.hide {
      display: none;
    }

     .review-changes {
        display: block;
        margin-top: 8px;
        text-align: left;
    }
  }
}

@media (max-width: 380px) {
  .notification-banner {
    padding: 8px 11px;

    .content {
      gap: 8px;
    }
  }
}