.review-field {
    padding: 0 16px 0 8px;
    line-height: 21px;
    letter-spacing: -0.1768px; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    border-left: 8px solid transparent;

    &.has-updates {
        border-left: 8px solid var(--eos-yellow);
    }
}

.review-field-title {
    color: var(--text-grey);
    font-weight: 700;
    font-size: 15px;
}

.review-field-data {
    min-height: 21px;
    overflow-wrap: anywhere;
    color: var(--main-colour);
    font-weight: 500;
    font-size: 17px;
    white-space: pre-line;
}

.review-textarea {
    font-size: 15px;
    font-weight: 400;
}