.docx-viewer-container {       
    text-align: left;
}

@media screen and (max-width: $breakpoint-tablet) { 
    .docx-viewer-container {
        transform: scale(0.70);
        margin-top: -150px;
    }
}
