.notification-content {
  border: none;
  background: initial;
  text-align: left;
  color: $eos-grey-1;
  font-weight: normal;

  width: 300px;
  min-height: 75px;
  background-color: $white; 
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 10px;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.07);
  position: absolute;
  z-index: 50;
  top: 75px;
  right: 10px;
  cursor: pointer;
  
 

  &::before {
      content: '';
      position: absolute;
      width: 19.52px;
      height: 19.52px;
      background-color: $white;
      top: -10px;
      right: 13px;
      transform: rotate(45deg);
      z-index: -10;
      
  
    }
  
    .inner-content {
      background: var(--eos-bg-blue-darker);
      height: 100%;
      z-index: 20;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-radius: 10px;
      
      .title {
        color: $primary-blue;
        font-size: 15px;
        text-align: left;
        font-weight: bold;
      }

      .desc {
        color: $eos-grey-1;
        font-weight: normal;
        font-size: 16px;
      }
    }

    .inner-content.has-been-read {
      background-color: $white
    }
}

.alert-wrapper {
  position:relative;

}

.notification-content.hide {
    display: none;
  
  }

  .read-message-dialog {
      .notification-icon {
       height: 47px;
       width:43px;
       fill: $eos-grey-1
     }

     .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      margin-bottom: $spacer-m;
      
     }
     .text {
      font-size: 15px;
      font-weight: 400;
      letter-spacing: -0.18px;
      color: rgba(48, 48, 48, 1);
      
     }

  }

@media screen and (max-width: $breakpoint-tablet) {
  .notification-content {
    right: -5px;
    top: 60px;
  }
}