.account-menu {
    width: 201px;
    height: 221px;
    background-color: white;
    position: absolute;
    right: 10px;
    top: 60px;
    z-index: 100;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 4px 3px rgba(0, 0, 0, 0.07);

    &::before {
        content: '';
        position: absolute;
        width: 19.52px;
        height: 19.52px;
        background-color: $white;
        top: -10px;
        right: 13px;
        transform: rotate(45deg);
    }

    ul {
        list-style: none;
        padding: $padding-default;
        font-weight: 400;

        button {
            width: 100%;
            margin-bottom: $spacer-m;
            font-size: 0.938rem;
            font-weight: 400;
            line-height: 21px;
            color: $eos-grey-1;

            &:hover {
                cursor: pointer;

                &:has(>.logout-text) {
                    color: $primary-blue;
                    font-weight: 700;
                }
            }
        }

        .logout {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0;
        }
    }

    .version {
        height: 32px;
        background-color: $eos-grey-4;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 8px;
        font-size: 0.75rem;
        color: $eos-grey-1;
        text-align: right;
        line-height: 16px;
        overflow: hidden;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.mobile-account-menu {
    display: none
}

.account-menu.hide {
    display: none;
}

.mobile-account-menu-overlay.hide,
.mobile-account-menu-parent.hide {
    display: none;

}

@media screen and (max-width: $breakpoint-tablet) {
    .account-menu {
        display: none;
    }

    .mobile-account-menu {
        display: flex;
    }

    .mobile-account-menu-overlay {
        position: fixed;
        top: 0;
        left: 0;
        background-color: black;
        opacity: 0.7;
        height: 100vh;
        width: 100%;
        z-index: 200;

        border: none;
    }

    .mobile-account-menu-parent {
        z-index: 200;

        .mobile-account-menu {
            width: 100%;
            border-radius: 0;
            background-color: white;
            min-height: 220px;
            position: absolute;
            left: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border: 1px solid rgba(0, 0, 0, 0.05);
            box-shadow: 0 4px 3px rgba(0, 0, 0, 0.07);
            z-index: 200;

            ul {
                list-style: none;
                padding: 22px;
                font-weight: 400;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-grow: 1;


                button {
                    width: 100%;
                    margin-bottom: $spacer-m;
                    font-size: 0.938rem;
                    font-weight: 400;
                    line-height: 21px;
                    color: $eos-grey-1;

                    &:hover {
                        cursor: pointer;

                        &:has(>.logout-text) {
                            color: $primary-blue;
                            font-weight: 700;
                        }
                    }
                }

                button.logout {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    margin-bottom: 0;
                }
            }

            .version {
                height: 32px;
                background-color: $eos-grey-4;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: flex-start;
                padding: 8px;
                font-size: 0.75rem;
                color: $eos-grey-1;
                text-align: right;
                line-height: 16px;
                overflow: hidden;

            }

        }
    }

}

.mobile-account-menu button,
.account-menu button {
    border: none;
    background: initial;
    text-align: left;
}