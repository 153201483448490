.donor-details-header {
    background-color: $primary-blue;
    width: 100%;
    color: white;
    padding: 14.5px 12px;

    .content {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        flex-wrap: wrap;
    }

    hr {
        margin: 9px 0;
    }

    &.focus-highlight:focus {
        outline: none !important;
        border: 3px solid var(--eos-yellow);
        padding: 12.5px 10px;
    }
}

.donor-details-header.condensed {

    .title {
        align-content: flex-start;
    }
}

@media screen and (min-width: $breakpoint-tablet) {
    .donor-details-header {

        padding: 16px 24px;

        hr {
            background-color: $eos-grey-4;
        }

        &.focus-highlight:focus {
            padding: 13px 21px;
        }

    }

    .donor-details-header.condensed {

        .content-parent {

            display: flex;
            justify-content: space-between;
            width: 100%;

            align-content: center;
            flex-wrap: wrap;
            gap: 5px;

            .divider {
                border-right: 1px solid white;
            }

            .content {
                height: 100%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 10px;
                flex-grow: 1;
            }

            .value {
                font-size: 15px;
                align-content: center;
                display: flex;
                flex-wrap: wrap;

                .secondary {
                    margin-left: 5px;
                }
            }

            .title {
                font-size: 12px;
                align-content: center;
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 0;
            }
        }
    }
}

.donor-details-header {
    .mobileOnly {
        .content:nth-of-type(3) {
            flex-wrap: nowrap;
            .donor-header-item {
                flex-basis: 50%;
            }
        }
    }
}
