.btn {
    height: 54px;
    background-color: var(--main-colour);
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:disabled {
        opacity: 0.5;
    }
}

.fullWidth-btn {
    display: block;
    min-width: 100% !important;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    color: white;
    opacity: 0.4;
}






@media (max-width: 768px) {
    .login-btn {
        min-width: 100%;
    }
}