.image-viewer-overlay {
    width: 100%;
    height: 100vh;
    z-index: 90;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--eos-grey-3);
    opacity: 1;    

    .image-viewer-gradient {
        width: 100%;
        height: 100vh;
        background: linear-gradient($eos-grey-1 0%, $eos-grey-2 20% 80%, $eos-grey-1 100%);
    }

    .pills {
        display: flex;
        height: 24px;
        gap: 1px;
    }

    .image-viewer-header-left-corner {
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: absolute;
        top: 0px;
        left: 0px;
        padding: 24px 24px 0px 24px;
        z-index: 100;
        transform: rotate(0deg);   /* need for rotated doc images else hides items */ 

        .ref-number {            
            min-width: 48.6px;
            height: 48.6px;
            font-size: 20.25px;
            line-height: 48.6px;
            display: block;
        }    

        .rotate-btn {
            width: 44px;            
            height: 44px;
            border-radius: 78.6px;
            background: #00000080;
            cursor: pointer;
        }        
    }

    .image-viewer-header-right-corner {
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 24px 24px 0px 24px;
        z-index: 9;
        transform: rotate(0deg);   /* need for rotated doc images else hides items */ 

        .btn-close-desktop {
            width: 112px;
            height: 45px;
            border-radius: 16px;
            background-color: white;    
            color: var(--text-grey);  
            font-size: 13px;
            font-weight: 600;      
            text-align: center;
            cursor: pointer;

            &:focus {
                outline: 4px double white;
            }
        }

        .btn-close-mobile {
            display: none;
        }
    }

    .container {
        min-width: 338px;
        min-height: 210px;
        background-color: transparent;
        z-index: 150;
        padding: 0;

        .contents {
            text-align: center;
            display: flex;

            .empty {
                align-self: center;

                .size {
                    position: relative;
                    top: -25px;
                }

                button {
                    background-color: transparent;
                    cursor: pointer;
                }
            }
        }        
    }

    .container.horizontal {
        height: auto;
        max-height: 100vh;
        .contents {
            height: 100vh;
            min-width: 100% !important;
            width: 100% !important;
            overflow-y: auto;
        }
    }

    .container.vertical {
        height: 100%;
        width: 100vw;
        .contents {
            height: 100%;
            width: 100vw;
            overflow: hidden;
            align-items: center;
            img {
                height: 100vw;
                width: 100vh;
                max-width: none;
                max-height: none;
            }
        }
    }

    .thumbnail-container {
        display: flex;
        justify-content: center;
        position: absolute;
        top: 100px;
        gap: 30px;
        width: 100%;
        z-index: 10;
        transform: rotate(0deg);   /* need for rotated doc images else hides items */     
    }

    .navigation {
        display: flex;
        justify-content: space-between;
        position: absolute;        
        top: 50%;        
        width: calc(100% - 32px);
        margin: 0 16px;

        .navigation-arrow {
            width: 43.77px;
            height: 44px;
            border-radius: 78.6px;
            background: #00000080;
            text-align: center;
            padding: 12px 6px;
            cursor: pointer;
            z-index: 100;

            &.right {
                transform: rotate(180deg);
            }

            &.hide {
                height: 0;
                padding: 0;

                svg {
                    display: none;
                }
            }
        }
    }

    .image-viewer-footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        bottom: 0px;
        padding: 0 24px 24px 24px;
        gap: 8px;
        color: white;
        font-weight: 700;
        background: linear-gradient(to bottom, rgba(30,30,30,0.1) -26.48%, rgba(10,10,10,1) 99.52%);
        z-index: 10;
        transform: rotate(0deg);   /* need for rotated doc images else hides items */     

        .title {
            font-size: 20px;
            line-height: 26px;
        }

        .description {
            font-size: 15px;
            line-height: 21px;
            min-height: 21px;
            line-break: anywhere;
        }
    }

    .download-error-dialog {
        .btn {
            width: 195px;
            color: white; 
        }
    }
}

@media screen and (max-width: $breakpoint-tablet) { 
    .image-viewer-overlay {
        .image-viewer-header-left-corner {
            padding: 8px;

            .ref-number {
                min-width: 24px;
                height: 24px;
                font-size: 14px;
                line-height: 24px;
            }            
        }

        .image-viewer-header-right-corner {
            padding: 8px;

            .btn-close-desktop {
                display: none;
            }

            .btn-close-mobile {
                display: initial;    
                font-size: 20px;
                cursor: pointer;
                background-color: transparent;          
            }    
        }

        .thumbnail-container {
            top: 50px;
            gap: 4px;
        }

        .container {
            margin: 0 !important;
            padding: 0;
            background-color: black;
        }

        .container.horizontal {
            min-width: 100%;
            .contents {
                height: 100vh;
            }
        }      
    }
}

@media screen and (max-height: 400px) { /* mobile in landscape view prevent comments overlapping nav arrows */
    .image-viewer-overlay {
        .image-viewer-footer {
            padding-left: 70px;
            padding-right: 70px;
        }
    }
}
