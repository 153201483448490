.breadcrumb {

    display: flex;
    flex-direction: row;
    align-items: center;

    svg: {
        display: flex;
        margin-right: 10px;
    }

    .title-span {
        margin-left: 10px;
    }
}