.last-viewed-container {
    border: none;
    background: initial;
    text-align: left;

    border-left: 8px solid transparent;
    padding-left: 8px;

    &.has-updates {
        border-left: 8px solid var(--eos-yellow);
    }

    .last-viewed-item {
        border: 0.5px solid #CCCCCC;
        border-radius: 10px;
        letter-spacing: -0.1768px;
        font-weight: 400;
        width: 100%;
        text-align: left;

        &:hover {
            cursor: pointer;
        }

        .lv-header {
            padding: 4px 12px;
            background: var(--eos-bg-blue);
            color: var(--primary-blue);
            font-size: 20px;
            line-height: 28px;
            justify-content: flex-start;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            min-height: 36px;
        }

        .lv-body {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 12px;
            padding: 12px;
        }

        .lv-title {
            font-size: 15px;
            line-height: 21px;
            color: var(--text-grey);
        }

        .lv-value {
            font-size: 20px;
            line-height: 28px;
            color: var(--primary-blue);
        }

        .lv-hospital {
            flex-shrink: 1;
            flex-grow: 0;
            flex-basis: 45%;
        }

        .lv-bloodgroup {
            flex-shrink: 0;
            flex-grow: 0;
            flex-basis: 125px;
        }

        .lv-dob {
            flex-shrink: 0;
            flex-grow: 0;
            flex-basis: 125px;
        }

        .lv-age {
            flex-shrink: 0;
            flex-grow: 0;
            flex-basis: 100px;
        }
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .last-viewed-item {
        .lv-hospital {
            min-width: 100%;
        }

        .lv-bloodgroup {
            min-width: 250px;
        }
    }
}