.attachment {
    .frame {
        display: flex;
        width: 250px;
        height: 187.5px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        cursor: pointer;

        .error-msg {
            display: none;
            background-color: $red;
            color: white;
            width: 100%;
            align-self: self-start;
            padding: 4px 8px 4px 8px;
            font-size: 12px;
            font-weight: 400;
        }

        .size {
            display: inline-block;
            text-align: center;
            position: relative;
            top: -70px;
            left: 0px;        
            width: 58px;
            font-size: 13px;
            font-weight: 600;
            color: var(--text-grey);
        }    
        
        &.error {
            border: 1px solid $red;
    
            .error-msg {
                display: flex;
            }
        }
    }
    
    .details {
        display: flex;
        flex-direction: column;
        gap: 12px;
        font-size: 12px;
        font-weight: 400;
        color: var(--text-grey);

        hr {
            width: 68px;
        }        

        .date {
            padding-right: 8px;
            margin-right: 8px;
            border-right: 1px solid var(--eos-grey-3);
        }

        .comments {
            font-size: 15px;
            word-wrap: anywhere;
        }
    }     
}