.empty-state {

    display: flex;
    flex-direction: column;
    padding: 16px 16px 32px 16px;

    .icon {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        top: 14px;
        margin-bottom: 40px;
        left: -8px;

        svg {
            width: 48px;
            height: 48px;
        }
    }

    p {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        align-items: flex-end;
        text-align: center;
        letter-spacing: -0.1768px;
        color: $primary-blue;
    }
}

.group-control {
    >fieldset >fieldset:has(.empty-state) {
        border: none;
        box-shadow: none;
    }
}

@media screen and (min-width: $breakpoint-tablet) {
    .empty-state {
        .icon {
            svg {
                width: 66.8px;
                height: 72.69px;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .group-control {
        .empty-state {
            padding: 0 8px 0 8px;
        }
    }
}