.no-records-message {

    padding: 8px;
    margin-top: 70px;
    box-sizing: border-box;
    align-self: center;

    .icon {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        top: 14px;
        margin-bottom: 40px;
        left: -8px;
    }

    h3 {
        color: $primary-blue;
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        margin-bottom: $padding-default;
    }

    p {
        color: $eos-grey-1;
        font-weight: 400;
        font-size: 15px;
        text-align: center;
        padding: 0 $padding-default;
    }
}

@media screen and (min-width: $breakpoint-tablet) {
    .no-records-message {
        max-width: 400px;
    }
}