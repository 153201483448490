.other-drug-view {
  width: 100%;
  border: 1px solid #005385;

  .other-drug-header {
    display: flex;
    min-height: 57px;
    border-bottom: 1px solid #005385;
    justify-content: space-between;


    .name {
      color: #303030;
      gap: 6px;
      padding-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      background-color: rgba(0, 122, 195, 0.05);
      font-size: 12px;

      .no-name {
        font-size: 15px;
      }

      .text {
        font-size: 15px;
        font-weight: 700;
      }
    }

    .administered {
      min-height: 57px;
      min-width: 50px;
      background-color: #005385;
      font-size: 20px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }

  .other-drug-body {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    padding-right: 12px;

    hr {
      background: #EEEEEE;
      border: 1px solid #EEEEEE;
      margin-bottom: 6px;

    }

    div .key {
      font-size: 12px;
    }
    .other-drug-dose {
      min-height: 47px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 6px;
      padding-top: 12px;
      padding-bottom: 12px;

      .dose {
        color: #005385;
        font-size: 15px;
        font-weight: 400;
      }
    }

    .other-drug-frequency {
      display: grid;
      grid-template-columns: repeat(4, auto);
      min-height: 47px;
      margin-bottom: 10px;

      div {
        text-align: left;
        display: flex;
        flex: 1;
        border-right: 1px solid #EEEEEE;
        min-height: 47px;
        padding-left: 12px;
        flex-direction: column;
        justify-content: center;
        gap: 6px;
      }

      div:last-of-type {
        border-right: none;
      }

      div:first-child {
        padding-left: 0;
      }

      .text {
        color: #005385;
        font-size: 15px;
        font-weight: 400;
      }
    }
  }
}


@media screen and (max-width: $breakpoint-tablet) {
  .other-drug-view {
    .other-drug-body {
      .other-drug-frequency {
        grid-template-columns: repeat(2, auto);
        gap: 15px;

        div:nth-child(3) {
          padding-left: 0;
        }

        div:nth-child(2) {
          border-right: none;
        }
      }
    }
  }
}