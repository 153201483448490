.activity-log {

    svg {
        stroke: none;
    }

}

@media screen and (min-width: $breakpoint-tablet) {
    .activity-log {

        width: 110px;

        .button-content {
            width: 100px;
        }

        .button-content label {
            width: 100px
        }
    }

}