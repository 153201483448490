.search-page {
    text-align: left;
    height: calc(100% - 44px);

    display: flex;
    flex-direction: column;

    hr {
        border-bottom: 1px solid #cccccc;
        opacity: 0.5;
        width: 100%;
    }

    .title-parent {
        padding: 0 16px;
    }

    .title {
        font-size: 25px;
        color: var(--primary-blue);
        font-weight: 500;
        padding: 0;
        margin: 8px 0;
        width: 100%;
        box-sizing: border-box;
    }

    .content-parent {
        // margin-right: 6px;
        padding: 8px 8px;
        // padding-right: 0;
        display: flex;
        justify-content: center;
        overflow-y: hidden;
        height: 100%;

        &::-webkit-scrollbar {
            width: 4px;
        }
    }

    .results {
        width: 100%;
        max-width: $width-main;
        display: flex;
        padding-right: 8px;
        overflow-y: auto;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        gap: 12px;
    }

    /** dialog styles */
    .record-not-found-dialog.dialog-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: $eos-grey-1;
        height: 366px;
        width: 290px;

        .dialog-btn {
            color: $white;
            width: 195px;
        }

        .dialog-text {
            font-weight: 400;
            font-size: 15px;
            height: 42px;
            width: 200px;
            color: $eos-grey-1;
        }

        h2 {
            font-weight: 700;
            line-height: 20px;
            font-size: 1.25rem;
            margin-bottom: $spacer-s;
        }

        .btn {
            width: 195px;
            height: 54px;
            color: white;
            font-weight: 800;
            font-size: 15px;
        }

    }

    .donor-details-dialog {

        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        color: $eos-grey-1;
        min-height: 587px;
        width: 343px;
        gap: 25px;
        padding: 24px;

        .donor-details {
            width: 100%;
        }

        .dialog-btn {
            color: $white;
            width: 195px;
        }

        .dialog-text {
            font-weight: 400;
            font-size: 19px;
            text-align: left;
            width: 100%;
            color: $eos-grey-1;
        }

        h2 {
            width: 100%;
            text-align: left;
            font-weight: 500;
            line-height: 31px;
            font-size: 25px;
            margin-bottom: $spacer-s;
        }

        .buttons {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .btn {
            display: inline-block;
            width: 137px;
            height: 54px;
            color: white;
            font-weight: 800;
            font-size: 15px;
        }

    }
}

@media screen and (min-width: $breakpoint-tablet) {
    .search-page {
        width: 100%;
        height: calc(100% - 63px);

        .title-parent {
            display: flex;
            justify-content: center;
            width: 100%;
        }

        .title {
            width: $width-main;
            font-size: 25px;
        }

        .content-parent {
            width: 100%;
            padding: 16px 16px;

            &::-webkit-scrollbar {
                width: 10px;
            }
        }

        .content {
            width: $width-main;
            text-align: center;
        }

    }
}