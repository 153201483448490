.header {

    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--eos-bg-blue);
   

    .content {
        width: 100%;
        max-width: $width-main;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 63px;
        color: white;
        height: 44px;
        padding-left: $padding-default;
        padding-right: $padding-default;
        padding-bottom: 0;
        position: relative;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

        .dot {
            display: none;
        }
    }

    .left-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .buttons {
        display: inline-flex;
    }

}
.header .desktopOnly {
   padding-left: $padding-default;

}

@media screen and (max-width: $breakpoint-tablet) {
    .header {
        &.mobileTabs {
            .content {
                background-color: $primary-blue;
                box-shadow: none;
            }        
        }   
    } 
}

.header.desktopOnly {
    display: none;
}

.dialog-overlay:has(.inactivity-dialog) {
    z-index: 999;
}

.dialog-content.logout-dialog,
.dialog-content.inactivity-dialog {
    gap: $spacer-m;

    .dialog-question,
    .dialog-timeout {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.1768px;
        color: $eos-grey-1;
    }

    .dialog-text {
        font-weight: 400;
        font-size: 0.938rem;
        line-height: 21px;
        text-align: center;
        letter-spacing: -0.1768px;
        color: $eos-grey-1;

    }

    .logout-dialog-btns {
        width: 100%;
        max-width: 300px;
        display: flex;
        justify-content: space-between;
        gap: $spacer-s;

        .btn {
            width: 137px;
            color: $white;
            font-weight: 800;
            font-size: 0.938rem;
            line-height: 18px;
            text-align: center;
            letter-spacing: -0.1768px;
        }
    }

}

.notification-icon-sm {
    height: 21px;
    width: 16px;
}


@media screen and (min-width: $breakpoint-tablet) {
    .header {
        padding-left: $padding-default;
        padding-right: $padding-default;
        width: 100%;

        .content {
            box-shadow: none;
            background-color: var(--eos-bg-blue);
            padding: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 63px;
            color: black;
        }
    }

    .header.desktopOnly {
        display: flex;
    }

}