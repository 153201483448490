.donor-header-item {
    display: inline-block;
    height: 100%;
    margin-right: 6px;

    .title {
        display: inline-block;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.1768px;
        margin-right: 6px;
    }

    .value {
        display: inline-block;
        font-weight: 400;
        font-size: 15px;
        line-height: 21px;
        letter-spacing: -0.1768px;

        .secondary {
            font-weight: 300;
        }

        .tertiary {
            font-weight: 600;
            font-size: 12px;
        }
    }

    .nowrap {
        white-space: nowrap;
    }

}

.donor-header-item.block {
    .title {
        margin-bottom: 4px;
        display: block;
    }

    .value {
        display: block;
    }
}

.donor-header-item.inline {
    display: flex;
    align-items: center;
    margin: 0;
}

@media screen and (min-width: $breakpoint-tablet) {
    .donor-header-item {
        display: inline-block;
        height: 100%;

        .title {
            font-weight: 400;
            font-size: 15px;
            line-height: 21px;
            letter-spacing: -0.1768px;
            margin-bottom: 4px;
        }

        .value {
            font-weight: 400;
            font-size: 22px;
            min-height: 26px;
        }

    }

}