.privacy {

    height: calc(85vh);
    overflow-y: scroll;
    padding-bottom: $padding-default;
   
    
}

.container {
    max-width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.privacy .container {
    flex-direction: column;
    align-items: flex-start;
    max-width: 788px;
    gap: 24px;
    margin-top: 24px;

    .cookie-button-container {
        font-size: 15px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: -1      0px;
    }   
}

.privacy .container h1 {
    font-size: 1.563rem;
    color: var(--text-grey);
}

.privacy .container p div {
    font-weight: 400;
    color: var(--text-grey);
    font-size: 0.938rem;
    margin-top: 12px;
    
}

.privacy .container p h2 {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--text-grey)
}

.container .privacy-span span {
    font-size: 0.938rem;
    color: var(--text-grey-2);
    font-weight: 400;
   
}

.cookies-we-use {
    p {
        margin-bottom: $spacer-s;
    }
}

@media (max-width: 768px) {
    .container {
            padding-left: 16px;
            padding-right: 16px;
        }
}

@media (min-width: 769px) {
    .container {
        padding-left: 24px;
        padding-right: 24px;
    }
}

@media (max-width: 480px) {
    .app-header, .privacy-span span {
        display: none;
    }
    .psuedo-select::before {
        content: 'Back to ';
    }
    
    .breadcrumb {
        background-color: var(--eos-bg-blue);    
    }
    .container p div:last-child {
        padding-bottom: 10px;
    }
        
}