#attachments-list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .attachment-filter-bar-container {
        min-height: 56px;
    }

    >hr:last-of-type {
        display: none;
    }
}


@media screen and (min-width: $breakpoint-tablet) { 
    #attachments-list {
        min-height: calc(100vh - 330px);        
    }
}

@media screen and (max-width: $breakpoint-tablet) { 
    #attachments-list {
        min-height: calc(100vh - 300px);        
    }
}
