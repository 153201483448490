.xlsx-viewer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    .table-container {
        height: 100%;
        max-width: 100%;
        overflow: scroll;

        table {
            border: 1px solid #716F64;
            border-width: 1px 0px 0px 1px;
            font-size: 14px;
            border-spacing: 0px;
            border-collapse: collapse;
            empty-cells: show;
        }

        th {
            background-color: #E6E6E6;
            border: 1px solid #B4B4B4;
            border-width: 0px 1px 1px 0px;
            font-weight: normal;
        }

        td {
            border: 0px;
            background-color: white;
            padding: 0px 4px 0px 2px;
            border: 1px solid #D4D4D4;
            border-width: 0px 1px 1px 0px;
            padding: 4px;
        }

        td:empty {
            visibility: visible;
        }

        td.heading {
            background-color: #E6E6E6;
            text-align: center;
            padding: 4px;
            border: 1px solid #B4B4B4;
            border-width: 0px 1px 1px 0px;
        }

        td.datetime {
            min-width: 125px;
        }
    }

    .sheets {
        display: flex;
        justify-content: flex-start;
        max-width: 100%;
        overflow-x: auto;
        background-color: var(--eos-grey-3);

        button {
            border: 1px solid #999999;
            padding: 4px 8px;  
            background-color: #D4D4D4;
            min-width: -moz-fit-content;
            min-width: fit-content;

            &.selected {
                border-bottom: 3px solid #217346;
                background-color: white;
                color: #217346;
                border-top: none;
                font-weight: 600;
            }
        }
    }
}