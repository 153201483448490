.breadcrumb-nav {
    height: 50px;
    background-color: var(--background-color);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    padding-right: $padding-default;
    
}

.breadcrumb-nav.desktopOnly {
    display: flex;
}

.breadcrumb-nav .content {
    width: 100%;
    max-width: $width-main;
    padding: 0;
    padding-left: $padding-default;
}

@media screen and (max-width: $breakpoint-tablet) {
    .breadcrumb-nav {
        background-color: var(--eos-bg-blue);
       
    }
    .breadcrumb-nav .content {
        padding-left: $padding-default;
    }

    .app-header,
    .privacy-span span {
        display: none;
    }

    .psuedo-select::before {
        content: 'Back to ';
    }

    .breadcrumb-nav.desktopOnly {
        display: none;
    }

}