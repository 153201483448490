.info-block {

    display: flex;
    flex-direction: column;
    padding: 16px 16px 32px 16px;
    border-left: $update-marker-width solid transparent;

    &.has-updates {
        border-left-color: var(--eos-yellow);
    }

    .icon {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        top: 14px;
        margin-bottom: 40px;
        left: -8px;

        svg {
            width: 48px;
            height: 48px;
        }
    }

    p {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        align-items: flex-end;
        text-align: center;
        letter-spacing: -0.1768px;
        color: $primary-blue;
    }
}

@media screen and (min-width: $breakpoint-tablet) {
    .info-block {
        .icon {
            svg {
                width: 66.8px;
                height: 72.69px;
            }
        }
    }
}