.percent-arrow {
    display: flex;
    position: relative;
    
    span {
        color: white;
        position: absolute;
        left: 4px;
        top: 10px;
        text-align: left;
    }
}