// mobile devices 

@media screen and (max-width: $breakpoint-tablet) {

  .mobile-side-nav {
    width: 24px;
    height: var(--display-height);
    position: fixed;
    background-color: $white;
    left: 0;
    top: 44px; //to be calculated from header
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-top: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: $spacer-m;
    padding-bottom: 85px;

    .hamburger {
      cursor: pointer;
      width: 16px;
      min-width: 16px;
      height: 12px;
      min-height: 12px;
    }

    .dot {
      width: 8px;
      min-width: 8px;
      height: 8px;
      min-height: 8px;
    }

    hr {
      width: 100%;
      border: 1px solid $eos-grey-4
    }
  }

  .side-menu {
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 0;
    right: 0;
    width: 100vw;
    z-index: 10;
    background-color: $white;
    padding-top: $spacer-xl;

    &.has-updates-bottom {
      border-bottom: 8px solid var(--eos-yellow);
    }

    &.has-updates-top {
      border-top: 8px solid var(--eos-yellow);
    }

    .sideMenu-close-icon {
      height: 14px;
      width: 14px;
      color: $eos-grey-1;
      position: absolute;
      right: 40px;
      cursor: pointer;
    }

    hr {
      border-top: 1px solid #cccccc;
      width: 100%;
      margin-top: 10px;
    }

    .nav {
      padding-left: 17px;
      padding-right: $spacer-s;

      hr {
        width: 100%;
        border: 1px solid $eos-grey-4
      }
    }

    .pointer {
      width: 19.46px;
      height: 18.97px;
      visibility: hidden;
    }

    .pointer.active {
      visibility: visible;
    }

    .submenu-link:focus {
      outline: 3px solid #88c4f8;
    }

    .menu-item {

      .title {
        border: none;
        background-color: $white;
        color: $eos-grey-1;
        font-weight: 800;
        font-size: 0.938rem;
        line-height: 15px;
        min-height: 42px;
        min-width: 270px;
        padding-top: 17px;
        padding-right: $spacer-s;
        padding-left: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon {
          transform: rotate(-90deg);
          transition: all .5s;
        }

        .icon.open {
          transform: rotate(90deg);
        }

        .icon-span {
          flex: 0;
          cursor: pointer;
        }

        .text-parent {
          display: flex;
          flex: 1;
          align-items: center;
          cursor: pointer;
          
          button {
            font-weight: 800;
            font-size: 0.938rem;
            background-color: $white;
            color: $eos-grey-1;
            margin-left: 8px;
            padding-left: 11px;
            border-left: 8px solid transparent;
            line-height: 32px;
            padding-right: 19px;
          }

          &.has-updates {
            button {
              border-color: var(--eos-yellow);
            }
          }
        }

        &.has-updates.active {
          color: $primary-blue;
          font-size: 15px;
        }
      }

      .sub-menu {
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
        list-style: none;
        width: 100%;
        padding-left: 18px;
        margin-left: 18px;

        li {
          width: 100%;
          margin-bottom: 2px;
          display: flex;
          align-items: center;
          height: 42px;
          letter-spacing: -0.1768px;
          color: $eos-grey-1;
          gap: 4px;

          a {
            text-decoration: none;
            color: $eos-grey-1;
            border-left: 8px solid transparent;
            padding-left: 10px;
            line-height: 32px;
            padding-right: 18px;

            &.has-updates {
              border-color: var(--eos-yellow);
            }
          }

        }


      }
    }
  }

  .mobile-side-nav.scroll {
    width: 44px;
    transition: width .25s ease-out;
  }

  .sub-menu.close {
    display: none;

  }

  .side-menu.close {
    transform: translateY(100vh);
    transition: transform .5s;
  }

  .side-menu.open {
    transform: translateX();
    transition: transform .5s;
  }

  button {
    border: none;
    background-color: $white;
  }

}