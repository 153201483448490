.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: center;
    align-items: center;
    min-height: 100vh;
    padding-bottom: $spacer-xl;

    .cta-container {
        .links {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
    }
}

.login-btn {
    max-width: 343px;
    min-width: 300px;
    width: 28.75vw;

}

.login .invisible {
    display: none;
}

.login .btn-content {
    font-weight: 800;
    color: #FFFFFF;
}

.login .login-question {
    font-weight: 400;
    font-size: 0.938rem;
    margin-top: 20px;
}

.login .contact {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    color: var(--main-colour);
    font-weight: 700;
}

.login {
    hr {
        border: 1px solid var(--eos-grey-3);
        margin: 24px 0;
    }

    .logo {
        margin-left: -23px;
        margin-bottom: 1.625rem;
    }    

    .env-text {
        margin-bottom: 1.625rem;
        font-size: 22px;
    }
}

/** dialog styles */
.dialog-content {
    display: flex;
    flex-direction: column;
    gap: $spacer-xl;
    align-items: center;
    text-align: center;
    color: $eos-grey-1;
    
    .dialog-btn {
        color: $white;
        width: 195px;
    }

    .username {
        padding-bottom: 18px;
        font-weight: 500;
        font-size: 16px;
    }

    div h2 {
        font-weight: 700;
        line-height: 26px;
        font-size: 1.25rem;
        margin-bottom: $spacer-s;
        
    }

    div p {
        font-weight: 400;
        line-height: 21px;
        font-size: 0.938rem;
    }

    .contact {
        margin-top: $spacer-s;
        display: flex;
        flex-direction: column;
        color: $primary-blue;
        font-weight: 700;
        line-height: 21px;
    }
    
}

@media (max-width: 768px) {
    .login {
        height: 100dvh;
        width: 100%;
        
    }

    .login .logo {
        margin: auto;
    }

    .login .cta-container {
        width: 100%;
        padding: 0px 1.25rem;
        padding-bottom: 3.75rem;
  
    }
 
}