.record-search {
    background-color: var(--eos-bg-blue);
    color: $primary-blue;
    font-weight: 700;
    font-size: 20px;
    color: $eos-grey-1;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
    width: 100%;
    box-sizing: border-box;

    p {
        margin-top: 0;
    }

    .search-icon {
        stroke: white;
    }

}

.record-search.overlay-search {
    position: absolute;
    z-index: 1;
    box-shadow: 0px 5px 4px 2px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: $breakpoint-tablet) {
    .record-search {
        width: 100%;
        background-color: $primary-blue;
        display: flex;
        justify-content: center;

        .record-search-content {
            width: $width-main;
        }

        .search-icon {
            stroke: $primary-blue;
        }

        p {
            color: #ffffff;
            font-size: 20px;
            margin-top: 0;
        }

        .controls {

            display: flex;
            width: 100%;
            padding-bottom: $padding-default;
            justify-content: space-between;

            span>div {
                display: inline-block;
                margin-right: 24px;

            }
        }

    }

    .record-search.overlay-search {
        position: relative;
        z-index: 0;
        box-shadow: none;
    }

}