.page {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: var(--eos-bg-blue);
    border-radius: 10px;    
    margin: 16px;
    max-width: 924px; 
}

.group-control {
    .group-legend {
        legend {
         
            width: 100%;
        }
    }
}

@media (max-width: 557px) {
    .page {
        margin-right: 8px;
    }
}