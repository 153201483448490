.page {
    letter-spacing: -0.1768px;

    h2 {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        padding-left: 16px;
    }

    fieldset {
        border: 0.5px solid var(--primary-blue);
        box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.1);
        background-color: var(--background-colour);
        padding-top: 16px;
        gap: 20px;
        display: flex;
        flex-direction: column;

    }

    .form-group-label {
        margin: -15px 16px -6px 16px;

        .small {
            font-size: 13px;
            font-weight: 600;
            color: var(--text-grey-2);
        }
    }

    .form-group {
        padding-left: 24px;

        .control-label {
            color: var(--text-grey);
            font-weight: 700;
            font-size: 15px;
        }

    }

    // page specific updates
    #CirculatoryDeathObservations .table .row {
        td {
            min-width: 95px;
        }

        td:first-of-type {
            min-width: 165px;
        }

        @media screen and (max-width: $breakpoint-tablet) {
            td:first-of-type {
                min-width: 116px;
            }
        }        
    }
}


.label-bold {
    margin-top: 25px;
    margin-bottom: 0px;
    margin-left: 14.5px;
    font-weight: 400;
}


.read-only-value {
    font-size: 20px;
    color: var(--main-colour);
    font-weight: normal;
    overflow-wrap: break-word;
    word-break: break-all;
}

.info-content {
    &.icon {
        margin-top: 44px;
        height: 43px;
    }
}

.modal {
    &.audit-control-factory-modal-main {
        .modal-dialog {
            .modal-main {
                display: flex;
                box-sizing: border-box;
                flex-direction: column;
                height: 100%;
                padding: 38px 0 30px;

                .modal-header {
                    padding-left: 35px;
                    padding-right: 35px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    h2 {
                        margin-top: 0;
                    }

                    .close-button {
                        cursor: pointer;

                        .icon-close {
                            height: 20px;
                            width: 20px;
                            background-size: contain;
                        }
                    }
                }

                .modal-body {
                    overflow-y: auto;
                    overflow-x: hidden;
                    margin-bottom: 30px;
                    padding-left: 35px;
                    padding-right: 35px;
                    padding-bottom: 20px;
                    flex-grow: 1;
                }

                .modal-footer {
                    padding-left: 35px;
                    padding-right: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    margin-top: 30px;

                    .standard-button.button-confirm {
                        cursor: not-allowed;
                    }

                    .standard-button.button-confirm:enabled {
                        background-color: var(--main-colour);
                        cursor: pointer;
                    }

                    .standard-button.button-confirm:disabled {
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
}

.tp-btn-space {
    margin-bottom: 0 !important;
    padding-left: 0 !important;
}

.checkbox-table-group {
    display: grid;
    background: none !important;
    border: none !important;
    padding-top: 0;
    margin-top: 0;

    .group-row:nth-child(1) {
        display: grid;
        grid-template-columns: 10rem 9.8rem 9.7rem 10rem;

        .label-bold {
            width: 100px;
            text-align: center;
        }
    }

    .group-row {
        display: grid;
        grid-template-columns: 8rem repeat(3, auto);

        .control-label {
            // padding: 0 32px 15px 32px;
            width: 8rem;
            height: 2rem;
            margin-top: 0;
            padding-bottom: 15px;
        }
    }
}