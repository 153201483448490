.drug-view {
  width: 100%;
  border: 1px solid #005385;

  .drug-header {
    display: flex;
    min-height: 40px;
    border-bottom: 1px solid #005385;
    justify-content: space-between;


    .name {
      color: #303030;
      gap: 6px;
      padding-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      background-color: rgba(0, 122, 195, 0.05);

      .text {
        font-size: 15px;
        font-weight: 700;
      }
    }

    .administered {
      min-height: 40px;
      min-width: 50px;
      background-color: #005385;
      font-size: 20px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }

  .large {
    min-height: 57px;

    .detail {
      font-size: 15px;
      font-weight: 700;
    }

    .name {
      .text {
        font-weight: 400;
      }
    }
  }

  .drug-body {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    padding-right: 12px;

    .loading-dose {
      display: flex;
      flex-direction: row;
      gap: 6px;
      padding-bottom: 8px;
      margin-bottom: 8px;
      margin-top: 8px;
      border-bottom: 1px solid #EEEEEE;
    }

    .parent {
      display: flex;
      flex-direction: column;
      gap: 6px;
      border-right: 1px solid #EEEEEE;
      padding-right: 16px;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 12px;


    }

    .parent:last-child {
      border-right: none;
    }

    .parent:first-child {
      padding-left: 0;
    }

    .diluent {
      display: flex;
    }

    .glucose {
      display: flex;
      margin-top: 12px;
      margin-bottom: 12px;
    }


    hr {
      background: #EEEEEE;
      border: 1px solid #EEEEEE;
      margin-bottom: 8px;
    }

    .value {
      color: #005385;
      font-size: 15px;
      font-weight: 400;
      height: 18px;
    }

    .key {
      font-size: 12px;
      font-weight: 400;

    }

    .unit {
      color: #303030;
    }

    .type-one {
      display: flex;
      min-height: 58px;
      align-items: center;

      .administered-value {
        flex: 1;
        border-right: 1px solid #EEEEEE;
        margin-right: 16px;
        margin-top: 8px;
        margin-bottom: 8px;
        font-size: 12px;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        gap: 6px;

      }

      .dosage {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }

    }


    .type-two {
      display: flex;
      padding-bottom: 10px;
      padding-top: 10px;

      .right-inner {
        display: flex;
        margin-top: 8px;

        .key {
          margin-bottom: 6px;
        }

        .current {
          margin-right: 14px;
          padding-right: 14px;
          border-right: 1px solid #EEEEEE;
        }
      }

      .right-parent {
        border-left: 1px solid #EEEEEE;
        padding-left: 14px;
      }

      .dates {
        display: flex;
        flex: 1;
        align-items: end;


        .commenced {
          border-right: 1px solid #EEEEEE;
          margin-right: 14px;
          padding-right: 14px;

        }


        .commenced,
        .max-dosage {
          display: flex;
          flex-direction: column;
          gap: 6px;
        }

        .drug-diluent {
          display: flex;
        }
      }

    }



  }
}

.drug-view-wrapper {
  width: 100%;
  padding: 0 20px 0 12px;
  border-left: 8px solid white;

  &.has-updates {
      border-left: 8px solid var(--eos-yellow);
  }
}

//not administer styles 
.not-drug-view {
  width: 100%;
  border: 1px solid #707070;

  .not-drug-header {
    display: flex;
    min-height: 40px;

    justify-content: space-between;

    // not administered
    .not-drug-header {
      display: flex;
      min-height: 40px;
      justify-content: space-between;
    }

    .not-name {
      color: #303030;
      gap: 6px;
      padding-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      background-color: 'white';
      font-size: 15px;
    }

    .not-administered {
      min-height: 40px;
      min-width: 50px;
      background-color: #707070;
      font-size: 20px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}

@media screen and (max-width: $breakpoint-tablet) {

  .drug-view {
    .drug-header {
      .name {
        padding-left: 6px;
      }
    }

    .drug-body {
      padding-left: 6px;

      .drug-frequency {
        grid-template-columns: repeat(2, auto);
        gap: 15px;

        div:nth-child(3) {
          padding-left: 0;
        }

        div:nth-child(2) {
          border-right: none;
        }
      }

      .type-two {
        flex-direction: column;

        .right-parent {
          border-left: none;
          padding-left: 0;
          padding-top: 8px;
          margin-top: 8px;
          border-top: 1px solid #EEEEEE;
        }

        .dates {
          flex-direction: column;
          align-items: start;
          gap: 20px;

          .commenced {
            border-right: none;
          }

          .drug-diluent {
            .first {
              border-right: 1px solid #EEEEEE;
            }

          }
        }
      }

      .diluent {

        flex-wrap: wrap;

        .first {
          flex: 1;
          width: 25%;
          padding-right: 12px;
        }

        .second {
          flex: 5;
          width: 75%;

        }

        .third {
          width: 100%;
        }



      }

      .parent:last-child {
        padding-left: 0;

      }

      .parent:nth-last-child(2) {
        border-right: 0;

      }

      .loading-dose {

        .first {
          border-right: 1px solid #EEEEEE;
        }

        .second {
          padding-left: 12px;
        }
      }


    }

    .glucose {
      display: flex;
      flex-direction: column;

    }
  }
}