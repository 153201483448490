.labelled-input {
    label {
        font-size: 15px;
        display: block;
        font-weight: 400;
        margin-top: 12px;
        color: $primary-blue;
    }

    input {
        width: 100%;
        height: 100%;
        max-width: 290px;
        height: 43px;
        max-height: 43px;
        border-radius: 5px;
        border: 1px solid var(--border-grey);
        padding: 0 13px;
        font-size: 15px;
        display: block;
    }

    ::placeholder {
        font-size: 15px;
        font-weight: 300;
        color: #464646;
        opacity: 1; /* Firefox */
      }

}


@media screen and (min-width: $breakpoint-tablet) {

    .labelled-input {

        width: 290px;

        label {
            color: #ffffff;
            font-size: 15px;
            display: block;
        }

        input {
            width: 100%;
            height: 100%;
            max-width: 290px;
            height: 43px;
            max-height: 43px;
            border-radius: 5px;
            border: 1px solid var(--border-grey);
        }
    }

}