.link-btn {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .link-btn-content {
        
        align-items: center;
        justify-content: center;
    }
}

