.terms-conditions {

    height: calc(85vh);
    overflow-y: scroll;
    padding-bottom: $padding-default;
   
    .container {
        max-width: 100%;
        min-height: 100vh;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        align-items: flex-start;
        max-width: 788px;
        gap: $spacer-s;
        margin-top: 24px;

        h1 {
            font-size: 1.563rem;
            color: var(--text-grey);
        }

        p, ul {
            font-weight: 400;
            color: var(--text-grey);
            font-size: 0.938rem;
        }

        ul {
            padding-left: $spacer-m;
        }

        h2 {
            font-size: 1.25rem;
            font-weight: 700;
            color: var(--text-grey)
        }
    }
}

@media (max-width: 768px) {
    .accessibility.container {
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media (min-width: 769px) {
    .accessibility .container {
        padding-left: 24px;
        padding-right: 24px;
    }
}

@media (max-width: 480px) {
    .app-header, .privacy-span span {
        display: none;
    }

    .psuedo-select::before {
        content: 'Back to ';
    }
    
    .breadcrumb {
        background-color: var(--eos-bg-blue);    
    }

    .accessibility .container p:last-child {
        padding-bottom: 10px;
    }        
}