.ref-number {
    border-radius: 27px;
    color: white;
    height: 24px;
    line-height: 24px;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 24px;
    font-size: 12px;
    font-weight: 400;
    background-color: #005385;
    text-align: center;
    vertical-align: middle;
}
