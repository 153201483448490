.review-block-field {
    box-sizing: border-box;
    padding: 0px;
    margin: 0 16px;
    margin-left: 8px;

    .review-block-field-title {
        color: var(--text-grey);
        font-weight: 700;
        font-size: 15px;
        align-self: center;
        line-height: 21px;
        padding: 8px;
    }

    .review-block-field-extratext {
        font-weight: 500;
        font-size: 13px;
    }


    &.switch {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: var(--primary-blue-opt05);
        border: 1px solid var(--primary-blue);

        .review-block-field-title {
            flex-basis: fit-content;
        }

        .review-block-field-data {
            background-color: var(--primary-blue);
            color: var(--eos-bg-blue);
            padding: 8px;
            font-weight: 400;
            font-size: 20px;        
            line-height: 23px;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 48px;

            &.small {
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;        
                color: var(--background-colour);
            }        
        }    
    }

    &.text {
        display: flex;
        flex-direction: column;;
        justify-content: flex-start;        

        .review-block-field-title {
            border: 1px solid var(--primary-blue);
            border-top: none;
            background-color: var(--primary-blue-opt05);
            width: 100%;

            :not(:first-child) {
                border-top: none;
            }    
        }

        .review-block-field-title:empty {
            display: none;
        }

        .review-block-field-data {
            border: 1px solid var(--primary-blue);
            padding: 8px 9px 9px 9px;
            font-weight: 400;
            font-size: 15px;
            line-height: 21px;
            color: var(--text-grey);
            min-height: 25px;
            white-space: break-spaces;
            overflow-wrap: anywhere;
        }    

        :not(:first-child) {
            border-top: none;
        }
        
    }

    &.not-clinically-significant {
        border-color: var(--border-grey);
        background-color: var(--background-colour);

        &.switch .review-block-field-data {
            background-color: var(--eos-grey-2);
            color: var(--eos-grey-5);
        }
    }

    &.has-updates {
        border-left: 8px solid var(--eos-yellow);
    }
}

.review-block-field-wrapper {
    border-left: 8px solid white;

    &.has-updates {
        border-left: 8px solid var(--eos-yellow);
    }
}

.review-block-group {
    .review-block-field-wrapper:not(:first-of-type) {
        .review-block-field {
            border-top: none;
        }
    }

    .hidden-section + .review-block-field {
        border-top: 1px solid var(--primary-blue);
    }

    .hidden-section:last-child {
        margin-bottom: 0px;
    }
}