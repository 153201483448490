.activity-log-container {
    background-color: white;
    position: absolute;
    right: 85px;
    top: 60px;
    z-index: 100;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 29px 0px #00000054;
    list-style-type: none;
    width: 298px;

    &::before {
        content: '';
        position: absolute;
        width: 19.52px;
        height: 19.52px;
        background-color: $white;
        top: -10px;
        right: 13px;
        transform: rotate(45deg);
        z-index: 50;
    }    

    > div {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        max-height: 474px;
        overflow-x: auto;
        z-index: 150;
        border-radius: 8px;        
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .activity-log-container {
        position: fixed;
        bottom: 0;
        left: 0;
        right:unset;
        top: unset;
        max-height: 393px;
        width: 100vw;
        border-radius: 0;
        z-index: 200;
        box-shadow: none;  
        
        .more-indicator {
            height: 30px;
            background-image: linear-gradient(#FFF, #303030);
            border-radius: 0;
        }

        &::before {
            display: none;
        }
    }    
}