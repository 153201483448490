.haemodilution-table {
    background-color: $eos-bg-blue;
    color: $eos-grey-1;
    margin: 0 16px;
    padding: 16px 16px;
    max-width: 448px;

    &.has-updates {
        border-left: 8px solid var(--eos-yellow);
        padding-left: 8px;
    }

    .title {
        font-weight: 700;
        font-size: 15px;
        line-height: 21px;
        display: flex;
        align-items: center;
        letter-spacing: -0.1768px;
        margin-bottom: 32px;

    }

    table {
        width: 100%;
    }

    .label {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        display: flex;
        align-items: center;
        letter-spacing: -0.1768px;
    }

    .column {
        display: inline-block;
    }

    .column-middle {
        margin: 0 5%;
        width: 30%;
    }

    .column-middle div {
        margin: auto;
    }

    .column-left {
        width: 40%;
    }

    .arrow-parent {
        display: flex;
        justify-content: center;
    }

    .value {
        color: $primary-blue;
    }

    .column-header {
        font-weight: 400;
        font-size: 15px;
        line-height: 21px;
        letter-spacing: -0.1768px;
    }

    td {
        padding-bottom: 16px;
    }

    span {
        color: $eos-grey-2;
        margin-left: 2px;
    }

    .total-box {
        max-width: 85px;
        padding: 0;
    }
}


@media screen and (min-width: 840px) {
    .haemodilution-table {
        width: 448px;
    }

    .column-middle div {
        margin: auto 57px;
    }

    .column-right {
        width: 125px;
    }

}