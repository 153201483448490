.button-cta {

    background-color: $primary-blue;
    color: white;
    width: 100%;
    border-radius: 5px;
    height: 54px;
    border: none;
    margin-top: 16px;
    margin-bottom: 16px;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 800;

    span {
        display: inline-block;
    }

    .icon {
        position: relative;
        left: -10px;
        top: 4px;
    }

    .text {
        line-height: 53px;
    }

    .disabled {
        background-color: var(--primary-blue-disabled);
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    .loading-spinner {
        height: 100%;
        fill: white;
        opacity: 0.5;
    }

}

.button-cta.disabled {
    opacity: 0.5;
}



@media screen and (min-width: $breakpoint-tablet) {
    .button-cta {
        width: 100%;
        max-width: 343px;
        max-height: 54px;
        background-color: #ffffff;
        color: var(--primary-blue);
        border: 1px solid var(--primary-blue);

        .loading-spinner {
            height: 100%;
            fill: $primary-blue;
            opacity: 0.5;
        }
    }

    .button-cta.disabled {
        .text {
            opacity: 0.5;
        }

        .icon {
            opacity: 0.5;
        }
    }

    .button-cta.disabled {
        opacity: 1;
    }


}