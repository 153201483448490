.group-control {
    .group-legend {
        legend {
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
            color: #FFFFFF;    
            background: var(--main-colour);
            padding: 5px 0 5px 16px;
        }
    }

    fieldset {
        padding: 16px 0 16px 0;

        >.hidden-section {
            margin: 0px 16px;
        }
    }
}