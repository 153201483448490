.attachment-tab-bar {
    height: 56px;
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 8px 16px;
    font-weight: 400;
    font-size: 15px;

    .filter {
        button {
            box-sizing: border-box;
            border: none;
            border-top: 3px solid white;
            border-bottom: 3px solid white;
            height: 40px;
            padding: 0 8px;
            background: white;
            color: $eos-grey-1;

            &.selected {
                border-bottom: 3px solid $primary-blue;
                font-weight: 700;
                color: $primary-blue;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .show-comments {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;

        &.hide {
            display: none;
        }

        button {
            width: 51px;
            height: 31px;
            margin-bottom: 4px;
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .show-comments {
        font-size: 12px;
    }
}