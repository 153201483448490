.group-row {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-flow: row dense;
}

@media (max-width: 1072px) {
    .group-row > *:nth-child(3) {
        grid-column: span 2;
    }
}

@media (max-width: 557px) {
    .group-row.mobile-1-col {
        display: flex;
        flex-direction: column;
    }        

    .group-row.mobile-2-col {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    }        

    .group-row.mobile-3-col {
        grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
    }        
}

.review-field-data {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
}