$padding-default: 16px;
$padding-default-wu: 8px;
$update-marker-width: 8px;
$breakpoint-tablet: 786px;
$primary-blue: #005385;
$eos-bg-blue: #F0F4F7;
$eos-grey-1: #303030;
$eos-grey-2: #707070;
$eos-grey-4: #EEEEEE;
$white: #FFFFFF;
$red: #AD1E1C;
$green-selected: #34C759;
$spacer-xl: 40px;
$spacer-l: 32px;
$spacer-m: 24px;
$spacer-s: 16px;
$width-main: 1194px;
$training-color: #9FCC2C;
$sat-color: #FF0000;
$uat-color: #4F28A0;

:export {
	paddingDefault: $padding-default;
	breakpointTablet: $breakpoint-tablet;
}

:root {
	--main-colour: #005385;
	--main-light-colour: #00B1D3;
	--background-colour: #FFFFFF;
	--font: 'Roboto', sans-serif;
	--primary-blue: #005385;
	--primary-blue-opt40: #00538566;
	--primary-blue-opt05: #0053850D;
	--primary-blue-disabled: #00538577;
	--padding: 16px;
	--border-grey: #707070;
	--eos-bg-blue: #F0F4F7;
	--eos-bg-blue-opt40: #F0F4F766;
	--eos-bg-blue-darker: #CCE2EF;
	--text-grey: #303030;
	--text-grey-2: #303030;
	--eos-yellow: #FEB902;
	--font: 'Roboto', sans-serif;
	--display-height: 100%;
	--eos-grey-2: #707070;
	--eos-grey-3: #cccccc;
	--eos-grey-5: #FBFBFB;
}


html {
    overflow: hidden;
    height: 100%;
} 

body {
    overflow: hidden;
    height: 100%;

}


#root {
	height: 100%;
}
.App {
    overflow: hidden;
    height: 100%;
}

body {
	margin: 0;
	font-family: var(--font);
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

button {
	font-family: var(--font);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	

}

a {
	color: #005385;
	font-weight: 600;
}



.desktopOnly {
	display: none;
}

.mobileOnly {
	display: initial;
}

.scroll-style-1 {
	&::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}
	
	&::-webkit-scrollbar-track {
		background: #cccccc;
		-webkit-border-radius: 25px;
		border-radius: 25px;
	}
	
	&::-webkit-scrollbar-thumb {
		background: var(--border-grey);
		-webkit-border-radius: 25px;
		border-radius: 25px;
		width: 10px;
		height: 10px;
	}
}

@media screen and (min-width: $breakpoint-tablet) {
	.mobileOnly {
		display: none;
	}

	.desktopOnly {
		display: initial;
	}

}

.fixed-scroll {
	position: fixed !important;
	scroll-margin-top: 50px;
}

.spin {
	animation: spin 1s linear infinite;
}

.env-bg-colour.training {
	background-color: $training-color !important;
}

.env-colour.training {
	color: $training-color;
}

.env-bg-colour.sat {
	background-color: $sat-color !important;
}

.env-colour.sat {
	color: $sat-color;
}

.env-bg-colour.uat {
	background-color: $uat-color !important;
}

.env-colour.uat {
	color: $uat-color;
}