.activity-log-item {
    line-height: 17.58px;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 8px;
    min-height: 77px;
    padding: 16px 8px 16px 16px;
    border: 2px solid transparent;    
    border-bottom: 1px solid var(--eos-grey-3);

    &.updated {
        background-color: var(--eos-bg-blue-darker);
    }

    &:last-of-type {
        border-bottom: none;
        border-radius: 0 0 8px 8px;
    }

    &:first-of-type {
        border-radius: 8px 8px 0 0; 
    }

    &:focus {
        outline: none !important;
        border-color: black;
        border-bottom-width: 2px;        
    }   

    .datetime {
        font-size: 14px;
        font-weight: 500;
        color: var(--text-grey-2);
    }

    .time {
        border-left: 1px solid var(--eos-grey-3);
        padding-left: 8px;
        margin-left: 8px;
    }

    .details-section {
        color: var(--primary-blue);
        font-weight: 700;
        font-size: 15px;
    }
}