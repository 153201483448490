.referral {
    height: 100%;

    .referral-content-parent {
        height: 100%;
        max-width: $width-main;
        margin: 0 auto;
        display: flex;
        justify-content: center;

        .referral-content-inner {
            overflow-y: scroll;
            background-color: var(--eos-bg-blue);

        }

        .referral-content {
            overflow-y: hidden;
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }
}

.referral-content-parent {
    padding-bottom: 115px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
}


@media screen and (max-width: $breakpoint-tablet) {
    .referral {
        .referral-content-parent {
            padding-bottom: 44px;
            padding-left: 0px;
            padding-right: 0px;
            margin-left: 24px;

            .referral-content {
                background-color: var(--eos-bg-blue);
            }

            .referral-content-inner {
                width: 100%;
                width: 98%;
                height: 100vh;
                overflow: auto;
                background-color: var(--eos-bg-blue);
                padding-bottom: 50px;
            }

        }

        .referral-content-parent.scroll {
            margin-left: 44px;
            transition: margin-left .25s ease-out;
        }
    }
}