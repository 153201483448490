.donor-details {

    text-align: left;
    .name {
        font-weight: 400;
        font-size: 15px;
        margin-bottom: 5px;
    }

    .value {
        color: $primary-blue;
        font-weight: 400;
        font-size: 20px;
        margin-bottom: $padding-default;
    }
}