.overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.overlay .cookie-banner {
    background-color: var(--background-colour);
    min-height: 21.87vh;
    padding: 2.5rem;
    display: flex;
    justify-content: space-between;
    
}

.cookie-banner .cookie-content {
    display: flex;
    flex-direction: column;
    text-align: start;
    gap: 10px;
   
}

 .cookie-content .cookie-title {
    font-weight: 700; 
    font-size: 1.25rem; 
    color: var(--text-grey);
 
    
}

.cookie-banner .cookie-text {
    max-width: 90%;
    line-height: 21px;
}

.cookie-banner .cookie-btn-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: flex-end;
}

.cookie-content a {
    font-size: 15px;
    color: var(--main-colour);
    font-weight: 600; 
    margin-top: 10px;
}

.cookie-banner .cookie-btn {
    width: 284px;
    height: 54px;
    background-color: var(--main-colour);
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cookie-btn .btn-content {
    font-weight: 800;
    color: #FFFFFF;
}



@media (max-width: 768px) {
    .cookie-banner {
        flex-direction: column;
    }
    .cookie-banner .cookie-content {
        text-align: center;
    }
    .cookie-banner .cookie-text {
        max-width: 100%;
        text-align: start;
        font-size: 15px;
    }
    .cookie-banner .cookie-btn-container {
        flex-direction: column;
        align-items: center;
        padding-top: 2rem;        
    }
    .cookie-banner .cookie-btn {
        width: 100%;    
    }
    .overlay .cookie-banner {
        padding: 1.5rem;
    }
}