.thumbnail-view {
    box-shadow: 0px 4px 4px 0px #00000080;
    cursor: pointer;

    >div {
        width: -moz-fit-content;    
        width: fit-content;  
        height: 54px;  
        background-color: var(--eos-grey-2);
    }

    .empty {
        width: 78px;
        height: 100%;
    }

    img {        
        height: 100%;
    }

    &:not(.selected) {
        img {
            opacity: 0.5;
        }
    }

    &.selected {
        img {
            opacity: 1;
        }

        >div {    
            border: 4px solid white;
        }
    }
}

@media screen and (max-width: $breakpoint-tablet) { 
    .thumbnail-view {
        >div {    
            width: -moz-fit-content;              
            width: fit-content;  
            max-width: 58px;
            height: 38px;  
            overflow-x: clip;                        
        }
    }
}