.organ-damage-pill {
    width: 126px;
    height: 24px;
    border-radius: 8px;
    font-weight: 700;
    font-size: 12px;
    color: #303030;
    border: 1px solid #AD1E1C;
    background-color: #FFF0F0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}