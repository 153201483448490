.hidden-section {
    width: calc(100% - 32px);
    border: none;
    margin: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 24px;
    background: $eos-grey-4;
}