.opaque-overlay {
    width: 100%;
    height: 100vh;
    z-index: 90;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    opacity: 1;
}

.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: grid;
    place-items: center;

    button {
        &:focus {
            outline: none !important;
            border: 3px solid var(--eos-yellow);
        }
    }
}

.dialog-overlay .dialog-content {
    min-width: 338px;
    height: auto;
    min-height: 210px ;
    background-color: var(--background-colour);
    border-radius: 10px;
    padding: var(--spacer-s);
    z-index: 150;
}

/* classes for the width */

.dialog-overlay .small {
    max-width: 338px !important ;
    padding: 48px 24px;
}

.dialog-overlay .medium {
    max-width: 348px !important;
    padding: 48px 24px;
}

.dialog-overlay .large {
    max-width: 481px !important;
    padding: 48px 24px;
}


@media (max-width: 768px) {
    .dialog-overlay .dialog-content {
        min-width: 90% !important;
        height: auto;
        max-width: 338px;
    }

    .dialog-overlay {
        padding: var(--spacer-s);
    }

    .dialog-content {
        margin: $spacer-m;
    }
}

