
// tablets and desktop
@media screen and (min-width: $breakpoint-tablet) {
 .mobile-side-nav {
  display: none;
 }
.side-menu {
  height: calc(var(--display-height) + 2px);
  min-width: calc(270px + $padding-default );
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding-right: $spacer-s;
  background-color: $white;
  padding-left: $padding-default;

  &.has-updates-bottom {
    border-bottom: 8px solid var(--eos-yellow);
  }

  &.has-updates-top {
    border-top: 8px solid var(--eos-yellow);
  }  
  
  &:hover {
    overflow-y: auto;
  } 

.sideMenu-close-icon {
  display: none;
}
 
 hr {
      border-top: 1px solid #cccccc;
      width: 100%;
      margin-top: $spacer-s;

    }

    .pointer {
      width: 19.46px;
      height: 18.97px;
      visibility: hidden;
    }

    .pointer.active {
      visibility: visible;
    }

    .menu-item {


      .title {

        min-height: 42px;
        min-width: 270px;
        padding-top: 17px;
        padding-right: $spacer-s;
        padding-left: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon {
          transform: rotate(-90deg);
          transition: all .5s;
        }

        .icon.open {
          transform: rotate(90deg);
        }

        .icon-span {
          flex: 0;
          cursor: pointer;
        }

        .text-parent {
          margin: 0;
          cursor: pointer;
          
          button {
            font-weight: 800;
            font-size: 0.938rem;
            border: none;
            background-color: $white;
            color: $eos-grey-1;
            margin-left: 20px;
            margin-left: 8px;
            padding-left: 11px;
            border-left: 8px solid transparent;
            line-height: 32px;
            padding-right: 19px;
          }

          &.has-updates {
            button {
              border-color: var(--eos-yellow);
            }
          }
        }
        
        .text.active {
          color: $primary-blue;
          font-size: 15px;
        }
      }

      .sub-menu {
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
        list-style: none;
        width: 100%;
        padding-left: 20px;
        margin-left: 18px;

        li {
          width: 100%;
          margin-bottom: 2px;
          display: flex;
          align-items: center;
          height: 42px;
          letter-spacing: -0.1768px;
          color: $eos-grey-1;
          gap: 4px;
          
          a {
            text-decoration: none;
            color: $eos-grey-1;
            border-left: 8px solid transparent;
            padding-left: 10px;
            line-height: 32px;
            padding-right: 18px;

            &.has-updates {
              border-color: var(--eos-yellow);
            }    
          }
        }
      }
    }
  }

  button {
    border: none;
    background-color: $white;
  }

  .sub-menu.close {
    display: none;
  }
}

 .sub-menu.close {
  display: none;
 }

 .sub-menu.open {
  display: block
 }
