.sticky-header-container {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 4px;
}

.sticky-header {
    padding: 16px;
    background-color: var(--eos-bg-blue);
    font-weight: 500;
    font-size: 25px;
    line-height: 31px;
    letter-spacing: -0.1768px;
    color: var(--text-grey);

    .switch-parent {
        display: flex;
    }

    .switch-label {
        font-weight: 400;
        font-size: 15px;
        color: $eos-grey-1;
        margin-right: 16px;
        line-height: 21px;
        vertical-align: middle;
        padding: 0 10px;
        margin: auto;
        text-align: right;
    }

    &.desktop {
        display: flex;
        justify-content: space-between;
    }

    &.mobile {
        display: none;
    }

    @media screen and (max-width: $breakpoint-tablet) {

        .switch-label {
            font-size: 12px;
            line-height: 16px;
        }

        &.desktop {
            display: none;
        }

        &.mobile {
            display: flex;
            justify-content: space-between;
        }
    }
}