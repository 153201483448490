.attachment-media {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 32px 0 32px 0;
    width: 100%;
    background-color: transparent;
    
    .title-details {
        display: flex;        
        justify-content: space-between;

        .title {
            font-size: 20px;
            font-weight: 700;
        }
        
        button {
            background-color: transparent;
            width: 140px;
            color: $primary-blue;
            text-decoration: underline;
            font-size: 15px;
            font-weight: 600;
            cursor: pointer;
        }        
    }    

    .frame {
        background-color: $eos-grey-2;
    }

    .files {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        gap: 16px;
    }

    .file {
        display: flex;
        flex-direction: column;
        gap: 8px;
        background-color: transparent;
        
        .frame {       
            .icon {
                margin: auto;
                width: 58px;
                overflow: hidden;
            }

            &.loading .icon {
                width: 32px;
            }

            &.loaded .icon {
                width: 100%;
            }

            &.error .icon {
                position: relative;
                top: -11px;
            }

            .size {
                display: inline-block;
                text-align: center;
                position: relative;
                top: -70px;
                left: 0px;        
                width: 58px;
                font-size: 13px;
                font-weight: 600;
                color: var(--text-grey);
            }    
        }
    }

    .pills {
        display: flex;
        gap: 4px;
    }    

    >.organ-damage-pill {
        margin-top: 10px;
    }    
}

.comments-only .attachment-media .files {
    display: none;
}