.attachment-viewer-overlay {
    width: 100%;
    height: 100vh;
    z-index: 90;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--eos-grey-3);
    opacity: 1;    

    .attachment-viewer-gradient {
        width: 100%;
        height: 100vh;
        background: linear-gradient(180deg, $eos-grey-1 0%, transparent 25%);
    }

    .attachment-viewer-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: absolute;
        padding: 24px 24px 0px 24px;

        .pill {
            height: 22px;
        }

        .btn-close-desktop {
            width: 112px;
            height: 45px;
            border-radius: 16px;
            background-color: white;    
            color: var(--text-grey);  
            font-size: 13px;
            font-weight: 600;      
            text-align: center;
            cursor: pointer;
        }

        .btn-close-mobile {
            display: none;
        }
    }

    .container {
        min-width: 338px;
        height: auto;
        min-height: 210px;
        max-height: 100vh;
        max-width: 90vw !important;
        padding-top: 75px;
        background-color: transparent;
        z-index: 150;

        .contents {
            height: calc(100vh - 75px);
            min-width: 100% !important;
            width: 100% !important;
            overflow-y: auto;
            text-align: center;
            display: flex;
        }        
    }
}

@media screen and (max-width: $breakpoint-tablet) { 
    .attachment-viewer-overlay {
        .attachment-viewer-header {
            padding: 4px 4px 0px 4px;
            align-items: center;

            .btn-close-desktop {
                display: none;
            }

            .btn-close-mobile {
                display: initial;    
                height: 14px;
                width: 14px;
                background-color: transparent;
                cursor: pointer;
            }    
        }

        .container {
            padding: 30px 0 0 0;
            margin: 0 !important;
            min-width: 100%;

            .contents {
                height: calc(100vh - 30px);
            }
        }
    }
}
