.complex-control-table {
  padding-left: $padding-default-wu;
  border-left: $update-marker-width solid white;

  .table-wrapper {
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      
    }
   
    &::-webkit-scrollbar-track {
      background: #cccccc;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      width: 6px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: var(--primary-blue);
      -webkit-border-radius: 5px;
      border-radius: 5px;
      width: 5px;
    }    
  }

  &.has-updates {
    border-left: $update-marker-width solid var(--eos-yellow);
  }  

  .table {
    padding-bottom: 10px;
    border-collapse: separate;    
    border-spacing: 4px 0;
    left: -16px;
    letter-spacing: 0px;

    .row {
      &:nth-of-type(odd) {
        background-color: white;

        .cell:first-of-type {
          background-color: white;
        }
      }

      &:nth-of-type(even) {
        background-color: #f0f4f7;

        .cell:first-of-type {
          background-color: #f0f4f7;
        }        
      }

      &:first-of-type {
        .cell:not(:first-of-type) {
          border-top: 1px solid #aec8dc;
        }
      }

      &:last-of-type {
        .cell:not(:first-of-type) {
          border-bottom: 1px solid #aec8dc;
        }
      }

      .cell {
        height: 37px;
        padding: 4.5px 8px;
        min-width: 119px;
      
        .units {
          font-weight: 400;
          font-size: 12px;
        }        
      }

      .col-header {
        vertical-align: top;
        padding: 0px;

        .content {
          padding: 1.5px 8px 4.5px 8px;
          border: none;
        }
      }

      td:first-of-type {
        min-width: 100px;
        font-weight: 700;
        font-size: 15px;
        word-wrap: initial;
        position: sticky;
        left: 0;
        z-index: 1;           
      }

      td:not(:first-of-type) {
        font-size: 15px;
        font-weight: 400;
        color: $primary-blue;
        border-right: 1px solid #aec8dc;
        border-left: 1px solid #aec8dc;
        z-index: -1;
      }

      .has-updates-box {
        height: 7px;
        width: 100%;
        background-color: white;
        border: none;
  
        &.has-update {
          background-color: var(--eos-yellow);
        }
      }            
    }
  }
}

@media (max-width: 768px) {
  .complex-control-table {
    &.scroll-bars-visible {
      .table {
        .row {
          td:nth-of-type(2) {
            border-left: 0;
          }        
        }
      }

      .cell {
        &:first-of-type {          
          -webkit-box-shadow: 5px 0 5px -2px  rgba(0, 0, 0, 0.5);
          -moz-box-shadow: 5px 0 5px -2px  rgba(0, 0, 0, 0.5);
          box-shadow: 5px 0 5px -2px  rgba(0, 0, 0, 0.5);      
        }
      }
    }
  }
}

/*  Different layout formats  */

.complex-control-table 
{
  &.row-column-headers-1 {
    .col-header {
      .content {
        font-weight: 700;
        font-size: 15px;
        color: black;
      }
    }
  }
}
     