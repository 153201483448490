.microbiology-table {
  padding-left: $padding-default-wu;
  border-left: $update-marker-width solid white;

  .table-wrapper {
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  
    &::-webkit-scrollbar-track {
      background: #cccccc;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      width: 6px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: var(--primary-blue);
      -webkit-border-radius: 5px;
      border-radius: 5px;
      width: 5px;
    }
  }

  &.has-updates {
    border-left: $update-marker-width solid var(--eos-yellow);
  }  

  .table {
    padding-bottom: 10px;
    border-collapse: separate;
    border-spacing: 4px 0;
    left: -16px;
    letter-spacing: 0px;

    .row {
      &:nth-of-type(even) {
        background-color: white;

        .cell:first-of-type {
          background-color: white;
        }
      }

      &:nth-of-type(odd) {
        background-color: #f0f4f7;

        .cell:first-of-type {
          background-color: #f0f4f7;
        }
      }

      &:first-of-type {
        .cell:not(:first-of-type) {
          border-top: 1px solid #aec8dc;
        }
      }

      &:last-of-type {
        .cell:not(:first-of-type) {
          border-bottom: 1px solid #aec8dc;
        }
      }

      .cell {
        height: 37px;
        padding: 4px;

        .units {
          font-weight: 400;
          font-size: 12px;
        }
      }

      td:not(:first-child) {
        position: relative;
        min-width: 133px;
        font-size: 15px;
        font-weight: 400;
        color: $primary-blue;
        border-right: 1px solid #aec8dc;
        border-left: 1px solid #aec8dc;
        z-index: 0;
      }

      span {
        padding: 8px 8px;
        display: inline-block;
        height: 100%;
        width: 100%;
        font-weight: 400;
      }

      .positive span {
        background-color: $red;
        color: white;
        font-weight: 700;
      }
    }

    .row > :first-child, 
    .headerRow > :first-child
    {
      font-size: 15px;
      white-space: nowrap;
      position: sticky;
      left: 0;
      z-index: 1;

      span {
        font-weight: 700;
      }
    }

  }

  .headerRow {
    :not(:first-of-type) {
      width: 133px;
      height: 52px;
      text-align: left;
      font-weight: 700;
      font-size: 15px;
      line-height: 21px;
      letter-spacing: -0.1768px;
    }

    th:first-child {
      background: white;
      position: sticky;
      border: white;
      background-color: white;
    }

    .disabledColumn {
      border: 1px solid var(--primary-blue-opt40);
      background-color: var(--eos-bg-blue-opt40);
      .header-text {
        color: var(--primary-blue-opt40);
      }
    }

    :not(.disabledColumn) {
      border: 1px solid $primary-blue;
      background-color: $eos-bg-blue;
      color: $primary-blue;
    }

    .has-updates-box {
      height: 7px;
      width: 100%;
      background-color: var(--eos-bg-blue-opt40);
      border: none;

      &.has-update {
        background-color: var(--eos-yellow);
      }
    }

    .header-text {
      display: flex;
      padding: 2px 8px 8px 8px;
      border: none;
      align-items: center;
    }
  }
}

@media (max-width: 768px) {
  .microbiology-table {
    .table {
      .row {
        td:nth-of-type(2) {
          border-left: 0;
        }        
      }
    }

    .cell {
        &:first-of-type {          
          -webkit-box-shadow: 5px 0 5px -2px  rgba(0, 0, 0, 0.5);
          -moz-box-shadow: 5px 0 5px -2px  rgba(0, 0, 0, 0.5);
          box-shadow: 5px 0 5px -2px  rgba(0, 0, 0, 0.5);      
        }
    }
  }
}