.attachment-document {
    width: 100%;
    display: flex;
    gap: 16px;
    border: 1px solid var(--eos-grey-3);
    align-items: center;
    padding: 8px;

    .frame {
        background-color: transparent;
        .icon {
            margin: auto;
        }
    }    
}

@media (max-width: $breakpoint-tablet) {
    .attachment-document {
        flex-direction: column;
        align-items: flex-start;

        .frame {
            align-self: center;
        }
    }
}