.header-button {

    button {
        border: none;
        border-bottom: 1px solid $eos-grey-1;
        font-size: 12px;
        font-weight: 500;
        border: none;
        background-color: var(--eos-bg-blue);
        line-height: 100%;
        margin-left: 8px;
        width: 41px;
        height: 41px;
        margin-bottom: 0;
        border-radius: 5px 5px 0px 0px;

        position: relative;
        top: 3px;

        label {
            display: none;
        }

        :hover {
            cursor: pointer;
        }
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .header-button.tab {
        button {
            background-color: var(--eos-bg-blue);

            .icon.inactive {
                stroke: white;
                fill: white;
            }

            .icon.active {
                stroke: $primary-blue;
                fill: $primary-blue;
            }

        }
    }

    .header-button.tab.inactive button {
        background-color: $primary-blue;
    }

  
}

@media screen and (min-width: $breakpoint-tablet) {

    .header-button {

        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        position: inherit;

        button {
            border: none;
            font-size: 12px;
            font-weight: 500;
            border: none;
            background-color: var(--eos-bg-blue);
            width: 70px;
            display: block;

            .button-content {
                display: flex;
                flex-direction: row;
                justify-content: left;
                height: 100%;
            }

            .icon {
                display: inline-block;
                margin-right: 8px;
            }

            .icon>svg {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .icon.active {
                fill: black;
                stroke: black;
            }

            .icon.inactive {
                fill: black;
                stroke: black;
            }

            label {
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: black;
            }

        }
    }

    .header-button.inactive button {
        background-color: var(--eos-bg-blue);
    }

    .header-button.mobileOnly {
        display: none;
    }
}