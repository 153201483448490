.header .print {
    .header-button {
        width: 120px;
        margin-right: 20px;

        button {
            width: 100%;
        }

        .loading-spinner {
            color: black;
            width: 18px;
        }

        .inactive {
            margin-right: 4px;
        }

        :has(.inactive ) {
            cursor: default;
        }
    }

    .btn.dialog-btn {
        font-weight: 800;
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .header .print {
        display: none;
        font-size: 15px;
    } 
}
